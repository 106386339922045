import React from "react";
import { navigate } from "gatsby";
import { Row, Col, Button } from "antd";
import { FullscreenOutlined } from "@ant-design/icons";

const ButtonCloseFullscreen = ({ url }) => {
  return (
    <>
      <Row align="center" style={{ marginBottom: "48px" }}>
        <Col>
          <Button
            size="large"
            type="dashed"
            onClick={() => {
              navigate(url);
            }}
            role="link"
          >
            Bekijk de schematische weergave <FullscreenOutlined />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ButtonCloseFullscreen;
