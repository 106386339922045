const config = {
  gatsby: {
    pathPrefix: "/",
    siteUrl: "https://www.lta-antistollingszorg.nl",
  },
  siteMetadata: {
    title: "LTA Antistollingszorg",
    description:
      "De landelijk transmurale afspraak (LTA) antistollingszorg geeft richting aan de samenwerking tussen de medisch specialist, huisarts, trombosedienst, openbaar apotheker, specialist ouderengeneeskunde, tandartsen en mondhygiënisten.",
    ogImage: null,
    favicon: "",
    versionNumber: "1.2.7 (28-09-2023)",
  },
};

module.exports = config;
