import React from "react";
// import Pre from "./pre";

import {
  Alert,
  Typography,
  Collapse,
  Divider,
  Card,
  Statistic,
  List,
  Button,
} from "antd";

const { Title, Paragraph, Text } = Typography;
const Panel = Collapse.Panel;
const CardGrid = Card.Grid;
const ListItem = List.Item;
const ListItemMeta = List.Item.Meta;

import { Link } from "gatsby";
import ButtonCloseFullscreen from "../ButtonCloseFullscreen";

import ContentLinkExternalFk from "../../../src/components/chart/ui/ContentLinkExternalFk";
import ContentLinkExternalKf from "../chart/ui/ContentLinkExternalKf";
import ContentLinkExternalRldb from "../chart/ui/ContentLinkExternalRldb";
import ContentLinkExternalFNT from "../chart/ui/ContentLinkExternalFNT";

/* eslint-disable react/display-name */
export const MDXLayoutComponents = {
  h1: (props) => <Title id={props.children} level={1} {...props} />,
  h2: (props) => <Title id={props.children} level={2} {...props} />,
  h3: (props) => <Title id={props.children} level={3} {...props} />,
  h4: (props) => <Title id={props.children} level={4} {...props} />,
  h5: (props) => <Title id={props.children} level={5} {...props} />,
  h6: (props) => <Title id={props.children} level={6} {...props} />,
  ul: (props) => <List id={props.children} bordered size="small" {...props} />,
  li: (props) => <ListItem id={props.children} {...props} />,
  hr: () => <Divider />,
  // wrapper: props => (
  //   <div style={{ padding: '20px', backgroundColor: 'tomato' }}>
  //     <main {...props} />
  //   </div>
  // ),
  ContentLinkExternalFk: ({ title, url }) => (
    <ContentLinkExternalFk title={title} url={url} />
  ),
  ContentLinkExternalKf: ({ title, url }) => (
    <ContentLinkExternalKf title={title} url={url} />
  ),
  ContentLinkExternalRldb: ({ title, url }) => (
    <ContentLinkExternalRldb title={title} url={url} />
  ),
  ContentLinkExternalFNT: ({ title, url }) => (
    <ContentLinkExternalFNT title={title} url={url} />
  ),
};

export const MDXGlobalComponents = {
  Divider,
  Paragraph,
  Alert,
  Collapse,
  Panel,
  Link,
  List,
  ListItem,
  ListItemMeta,
  Card,
  CardGrid,
  Statistic,
  Button,
  ButtonCloseFullscreen,
};
