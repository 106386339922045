import React from "react";

import { MDXProvider } from "@mdx-js/react";
import { MDXLayoutComponents, MDXGlobalComponents } from "./mdxComponents";
import { ConfigProvider } from "antd";

import nlNL from "./nl_NL";

import "../styles/stylesheet.scss";

const Layout = ({ children }) => (
  <MDXProvider
    components={{
      ...MDXLayoutComponents,
      ...MDXGlobalComponents,
    }}
  >
    <ConfigProvider locale={nlNL}>{children}</ConfigProvider>
  </MDXProvider>
);

export default Layout;
